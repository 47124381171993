<template>
	<PopoverMenu
		:menu-hidden="menuHidden"
		:minWidth="218"
		:dark="dark"
		:triggerLink="mainLink"
		:disabled="!customer"
	>
		<template #title>
			<span class="hidden-mobile">{{ loginText }}</span>
		</template>
		<template #icon>
			<TnIcon
				name="person"
				size="l"
			/>
		</template>
		<template #menu>
			<ul>
				<li
					v-for="(shortcut, key) in headerLoginShortcuts"
					:key="`customer-${key}`"
				>
					<LinkInline
						@click="logout"
						v-if="shortcut.to === '/logout'"
						>Logg ut
					</LinkInline>
					<LinkInline
						:dark="dark"
						color="black"
						:tabindex="0"
						v-bind="shortcut"
						v-else
					/>
				</li>
			</ul>
		</template>
	</PopoverMenu>
</template>
<script>
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";
import { useUserStore } from "~/pinia/platform/user/user.ts";
import { mapActions, mapState } from "pinia";

export default defineNuxtComponent({
	name: "CustomerMenu",

	props: {
		headerLogin: { type: Object, default: null },
		dark: { type: Boolean, default: false },
		"menu-hidden": { type: Boolean, default: false },
	},

	computed: {
		...mapState(useUserStore, ["customer"]),
		dynamicContent() {
			return this.$dynamicContent({
				"customer-firstname": this.customer?.firstName,
			});
		},
		mainLink() {
			return this.customer ? this.headerLoginShortcuts?.[0]?.to : this.logoutLink?.to || this.logoutLink?.href;
		},
		loginText() {
			if (this.customer?.firstName) return this.dynamicContent(this.headerLogin?.trigger?.loggedIn);
			if (this.$config.public.previewMode && useGizmoStore().loggedIn) return "Gizmo";
			else return this.headerLogin?.trigger?.loggedOut?.linkText;
		},
		logoutLink() {
			return LinkViewModel(this.headerLogin?.trigger?.loggedOut);
		},
		headerLoginShortcuts() {
			return this.headerLogin?.menu?.shortcuts?.map((shortcut) => LinkViewModel(shortcut));
		},
	},
	methods: {
		...mapActions(useUserStore, ["logout"]),
	},
});
</script>
<style lang="scss" scoped>
:deep() {
	.tn-list--item:last-child {
		margin-bottom: 0;
	}
}
</style>
