<template>
	<div>
		<LinkWrap
			v-for="(segment, index) in segments"
			:key="`${segment.to}-${index}`"
			class="segment-button"
			:class="{ active: segment.active, dark }"
			:to="segment.to"
		>
			{{ segment.text }}
		</LinkWrap>
	</div>
</template>

<script>
import SupportsDarkMode from "../../../../../mixins/platform/SupportsDarkMode.js";

export default defineNuxtComponent({
	name: "SegmentButtons",
	props: ["segments"],
	mixins: [SupportsDarkMode],
});
</script>

<style lang="scss" scoped>
.segment-button {
	text-decoration: none;
	font-size: 18px;
	padding: 5px 12px;
	text-align: center;
	color: inherit;

	&.dark {
		color: #fff;
	}

	+ .segment-button {
		margin-left: 16px;
	}

	&.active {
		border-bottom: 1px solid $color-primary-telenor;
	}
}

@include breakpoint(mobile) {
	.segment-button {
		padding: 5px 12px;
	}
}
</style>
