<template>
	<li
		class="dropdown-category"
		:class="[{ columns: useTwoColumns }, { footer: isFooter }, { dark: isDark }, { iphonecolumns: isIphone }]"
	>
		<div class="dropdown-link">
			<div
				class="hidden-desktop"
				@click="toggleLinks"
			>
				<div class="dropdown-header">
					<TnHeading
						:class="{ 'margin-top-2xl': isFirstItem }"
						type="h6"
						size="xs"
						bold
					>
						{{ title }}
					</TnHeading>
					<LazyExpandIcon
						:class="{ 'margin-top-2xl': isFirstItem }"
						:expanded="linksOpen"
						:color="colorScheme"
					/>
				</div>
				<TnTransitionHeight :show-if="linksOpen">
					<ul class="dropdown-link-container margin-top-l">
						<li
							v-for="(link, index) in menuLinks"
							:key="`link-${index}`"
						>
							<LazyLinkInline
								v-if="link.to || link.href"
								v-bind="link"
								:dark="isDark"
							/>
							<div
								v-else-if="link.text"
								v-html="link.text"
							/>
						</li>
					</ul>
				</TnTransitionHeight>
				<TnDivider
					width="full"
					size="l"
					:dark="isDark"
				/>
			</div>

			<div class="dropdown-header hidden-mobile">
				<LazyLinkInline
					v-if="categoryHeading && (categoryHeading.to || categoryHeading.href)"
					v-bind="categoryHeading"
					:dark="isDark"
					class="category-heading hidden-mobile"
				/>
				<TnHeading
					v-else
					type="h6"
					size="xs"
					bold
				>
					{{ title }}</TnHeading
				>
				<ul class="dropdown-link-container">
					<li
						v-for="(link, index) in menuLinks"
						:key="`link-${index}`"
					>
						<LazyLinkInline
							v-if="link.to || link.href"
							v-bind="link"
							:dark="isDark"
						/>
						<div
							v-else-if="link.text"
							v-html="link.text"
						/>
					</li>
				</ul>
			</div>
		</div>
	</li>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "~/pinia/platform/user/user.ts";

export default defineNuxtComponent({
	name: "menu-category",

	props: {
		index: { type: Number },
		menuLinks: { type: Array, default: () => [] },
		categoryHeading: { type: [String, Object] },
		useTwoColumns: { type: Boolean, default: false },
		colorScheme: { type: String, default: "white" },
		isFooter: { type: Boolean, default: false },
		dark: { type: Boolean, default: false },
	},

	data() {
		return {
			isDark: this.isFooter || this.dark,
			linksOpen: false,
		};
	},

	computed: {
		...mapState(useUserStore, ["userAgent"]),
		title() {
			return typeof this.categoryHeading === "object" && this.categoryHeading !== null
				? this.categoryHeading.text
				: this.categoryHeading;
		},
		isIphone() {
			return this.userAgent.indexOf("iphone") > -1;
		},
		isFirstItem() {
			return this.index === 0;
		},
	},

	methods: {
		toggleLinks() {
			this.linksOpen = !this.linksOpen;
		},
	},
});
</script>

<style lang="scss" scoped>
.dropdown-category {
	width: auto;
	flex-grow: 1;

	@include font-text-m;

	&.columns {
		flex-grow: 4;

		ul.dropdown-link-container {
			columns: 2;
		}

		&.iphonecolumns {
			ul.dropdown-link-container {
				columns: 1;
			}
		}
	}

	.category-heading {
		font-weight: bold;
		color: $color-neutrals-black;
	}

	:deep(.link) {
		display: inline;

		.first,
		.last {
			display: inline;
		}
	}

	.dropdown-link-container {
		margin-top: 15px;
		line-height: 32px;
		color: $color-neutrals-700-shade;

		.link {
			color: $color-neutrals-100-tint;
		}
	}

	&.dark,
	&.footer {
		.category-heading {
			color: $color-neutrals-white;

			.link {
				color: $color-neutrals-white;
			}
		}
	}

	&.footer {
		.dropdown-header {
			@include font-text-xl;
		}

		.dropdown-link-container {
			opacity: 0.8;
		}
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	@media (max-width: $size-screen-m) {
		width: calc(100% / 3);
		margin-bottom: 30px;
	}

	@include breakpoint(mobile) {
		width: 100%;
		flex-grow: 1;
		margin-bottom: 0;

		.dropdown-link {
			line-height: 44px;
		}

		.dropdown-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.dropdown-link-container {
			overflow: hidden;
			line-height: 44px;
			margin-top: 0;
		}

		.expand-icon {
			margin-right: 5px;
		}
	}
}
</style>
